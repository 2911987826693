header {
  width: 100%;
  padding: 2rem;
  @include flexbox(0 0 auto, row, flex-start, center);
  flex-wrap: wrap;
  img {
    width: 35%;
    max-width: 45rem;
    @include breakpoint(xs) {
      width: 100%;
    }
  }
  .navigation {
    @include flexbox(0 0 auto, row, flex-start, center);
    margin-right: 2rem;
    li {
      font-size: 1.2rem;
      margin: 1.5rem;
      cursor: pointer;
      &.selected a:link,
      &.selected a.active {
        color: $theme-color-orange;
      }
    }
    @include breakpoint(xs) {
      display: none;
    }
  }
  .church-label {
    font-size: 1.4rem;
    text-align: right;
    font-weight: 700;
    border-left: 1px solid #d9d8d4;
    padding: 0 2rem;
    .sub {
      font-size: 1.3rem;
      font-weight: 500;
      margin-top: 0.3rem;
    }
    @include breakpoint(xs) {
      text-align: center;
      width: 100%;
      margin: 2rem 0 0 0;
      border-left: none;
    }
  }

  @include breakpoint(xs) {
    padding: 2rem 1rem;
  }
}
