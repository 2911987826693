.account-menu {
  @include flexbox(0 0 auto, column, flex-start, flex-end);
  display: none;
  // position: absolute;
  // top: 4rem;
  // right: 0;
  background: $background;
  z-index: $bringToTop;
  margin-top: 0.6rem;
  width: 100%;

  .user-type {
    text-align: center;
    background: $textstandard;
    color: $textalternative;
    padding: 0.3rem 0.8rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8rem;
    margin: 0 1rem;
  }
  ul {
    width: 100%;
    margin-top: 1rem;
  }
  li {
    @include flexbox(0 0 auto, row, flex-start);
    padding: 0.8rem 1.3rem 0.8rem 1rem;
    font-size: 1rem;
    transition: ease-in-out 120ms background;
    position: relative;
    .sub-text {
      font-size: 0.9rem;
      font-weight: 300;
      margin-top: 0.1rem;
    }
    .menulabel {
      @include flexbox(1 1 auto, column, flex-start, flex-start);
    }
    .icon {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
    .linkbutton {
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: $textstandard;
        text-decoration: none;
      }
    }
    &:hover {
      background: $textstandard;
      color: white;
      cursor: pointer;
      .submenu {
        display: block;
      }
      & > .linkbutton {
        &:link,
        &:visited,
        &:hover,
        &:active {
          color: $textalternative;
          text-decoration: none;
        }
      }
    }
  }
  .submenu {
    position: absolute;
    left: -45%;
    top: 0;
    background: $background;
    border-radius: 0.8rem;
    color: $textstandard;
    display: none;
    font-size: 0.8rem;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.32);
  }

  //Side menu nav items
  .nav-side-menu {
    display: none;
    margin-bottom: 1rem;
    background: $gridstripes;
  }
  //Responsive
  @include breakpoint(xs) {
    @include flexbox(0 0 auto, column, flex-start);
    position: fixed;
    width: 85%;
    background: $background;
    padding: 0;
    margin: 0;
    left: 0;
    box-shadow: none;
    border-radius: 0;
    top: 21rem;
    overflow-y: auto;
    overflow-x: hidden;
    li {
      padding: 1.5rem 1.8rem 1.5rem 1.5rem;
      font-size: 1.8rem;
      border-radius: 0;
      font-weight: 300;
      width: 85vw;
      .icon {
        margin-right: 0.9rem;
        padding-right: 0.9rem;
        font-size: 2rem;
      }
      &:first-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:active {
        .submenu {
          display: block;
          width: 70vw;
        }
      }
    }
    .backgroundClickContainer {
      display: none;
    }
    .submenu {
      position: relative;
      margin-left: -41vw;
      box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.32);
      background: $background;
      border-radius: 0;
      color: $textstandard;
      display: none;
      font-size: 0.8rem;
    }
    .nav-side-menu {
      @include flexbox(0 0 auto, column, flex-start);
    }
  }
}
