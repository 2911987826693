.floor-progress {
  height: 100%;
  width: 100%;
  position: absolute;
  @include flexbox(0 0 auto, column, flex-end, flex-start);
  .progress-labels {
    @include flexbox(0 0 auto, column, center, center);
    z-index: 1;
    color: $textalternative;
    width: 100%;
    position: absolute;
    bottom: 45%;
    h1 {
      line-height: 1.45;
      color: $textalternative;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 800;
      text-shadow: 0px 0 1rem rgba(0, 0, 0, 0.73);
      margin: 0;
    }
    h3 {
      margin: 0;
      line-height: 1;
      color: $textalternative;
      font-size: 1.5rem;
      font-weight: 800;
      text-shadow: 0px 2px 0.8rem rgba(0, 0, 0, 0.93);
    }
  }
  .progress-bar {
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
}

.donation-form {
  .title {
    @include flexbox(0 0 auto, row, flex-start, flex-start);
    flex-wrap: wrap;
    margin-bottom: 1rem;
    font-weight: 800;
    font-size: 2rem;
  }
  .ant-form-item {
    @include flexbox(0 0 auto, column, flex-start, flex-start);
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.2rem;
    line-height: 1.5715;
    margin-bottom: 1.2rem;
    @include breakpoint(xs) {
      margin-bottom: 2rem;
    }
  }
  .ant-form label {
    font-size: 1.4rem;
  }
  .ant-form-item-label > label {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0.1rem;
    @include breakpoint(xs) {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
  .ant-form-item-control {
    width: 100%;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional),
  .ant-form-item-label > label {
    &::before,
    &::after {
      display: none;
    }
  }
  h1,
  h2 {
    line-height: 1.2;
    margin: 0;
  }
  .btn-note {
    margin-top: 0.5rem;
  }

  .ant-input,
  .ant-input-number-input {
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.5715;
    border: 1px solid #d9d9d9;
    border-radius: 0.5rem;
  }
  .ant-input-number {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.9;
    border-radius: 0.5rem;
    .ant-input-number-input {
      line-height: 1.9;
      border: none;
    }
  }

  .total {
    margin-top: 0.2rem;
    font-weight: 800;
  }
  .amount-label {
    font-size: 1.1rem;
    margin-left: 1rem;
  }
  .rupee-label {
    margin-right: 1rem;
    font-weight: 700;
  }
  .any-amount-row {
    min-height: 3rem;
  }
  .selected-floor {
    label {
      font-size: 1.6rem;
      font-weight: 400;
    }
    margin: 1.3rem 0 1.6rem 0;
    strong {
      margin-left: 0.3rem;
      font-weight: 700;
      opacity: 0.6;
      padding: 0 1rem;
      border-radius: 0.5rem;
      background: darken($textstandard, 7%);
      color: $textalternative;
    }
  }
  .ant-radio-wrapper,
  .ant-radio-group {
    @include flexbox(0 0 auto, row, flex-start, center);
    flex-wrap: wrap;
    font-weight: 600;
  }
  .ant-radio-wrapper {
    @include breakpoint(xs) {
      padding: 1.2rem 0;
    }
  }
  .ant-radio-button-wrapper {
    margin-bottom: 1.5rem;
    line-height: 1;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    min-width: 13rem;
    text-align: center;
    height: 2.5rem;
    padding: 0 1.5rem;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2.5rem;

    @include breakpoint(xs) {
      height: 3.5rem;
      line-height: 3.5rem;
    }
    &:first-child {
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:hover {
      color: $theme-color-orange;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: $textalternative;
    background: $theme-color-orange;
    border-color: $theme-color-orange;
    &.anybtn .icon {
      background: url('../../../images/anybtnselected.svg') no-repeat center
        center;
      background-size: 100% auto;
    }
    &.blockbtn .icon {
      background: url('../../../images/blockbtnselected.svg') no-repeat center
        center;
      background-size: contain;
    }
    &:hover {
      color: $textalternative;
    }
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: $theme-color-orange;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $theme-color-orange;
  }

  .anybtn .icon {
    display: inline-block;
    background: url('../../../images/anybtn.svg') no-repeat center center;
    background-size: 100% auto;
    margin-right: 0.5rem;
    height: 1.4rem;
    width: 2rem;
    margin-bottom: -0.2rem;
    margin-left: -0.2rem;
  }
  .blockbtn .icon {
    display: inline-block;
    background: url('../../../images/blockbtn.svg') no-repeat center center;
    background-size: contain;
    margin-right: 0.5rem;
    height: 1.3rem;
    width: 2rem;
    margin-bottom: -0.2rem;
    margin-left: -0.2rem;
  }
}
.info-column .donation .donate {
  .security-notice.aligned {
    @include flexbox(1 1 auto, row, flex-start, flex-start);
    // max-width: 33vw;
    margin-top: 1rem;
    .lockicon {
      margin-top: 0.5rem;
    }
    .genielogos {
      height: 4rem;
      margin: 0.5rem 0;
    }
    p {
      margin: 0;
    }
  }
}

.currency-table {
  font-size: 1.1rem;
  margin: 0;
  a {
    font-weight: bold;
    color: $theme-color-orange;
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    font-weight: 700;
  }
  ul {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    li {
      @include flexbox(1 1 auto, row, flex-start, flex-start);
      line-height: 2.5;
      .label {
        width: 7rem;
        margin-right: 2rem;
        padding: 0 1rem;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
      &:nth-child(even) {
        background: rgba(0, 0, 50, 0.08);
      }
    }
  }
}

.currency-btn {
  font-weight: 800;
  font-size: 1rem;
  opacity: 1;
}
