.btn {
  position: relative;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 0.9rem;
  margin-right: 1rem;
  padding: 0 1rem;
  height: 2.2rem;
  line-height: 0;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);

  text-transform: uppercase;
  i {
    margin-right: 0.4rem;
    // font-size: 0.9rem;
    &.dropdown-arrow {
      margin: 0 -0.3rem 0 0.4rem;
    }
  }
  &.unrounded {
    border-radius: 0.4rem;
  }
  &.no-shadow {
    box-shadow: none;
  }
  &.icon-only {
    i {
      margin-right: 0;
    }
  }
  &.no-flex {
    display: inline-block;
  }
  &.remove-margin-right {
    margin-right: 0;
  }
  &.empty-btn {
    background-color: transparent;
    border-color: transparent;
    color: $textstandard;
    opacity: 0.7;
    box-shadow: none;
    margin-right: 0;
    font-size: 1.3rem;
    padding: 0.5rem;
    // border-left: 1px grey dotted;
    border-radius: 0;
    &:active {
      background-color: $background;
      opacity: 0.9;
    }
    i {
      margin-right: 0;
    }
  }
}

//Batch list screen
.green-action-btn,
.create-new-btn {
  background-color: $theme-color-green;
  border-color: $theme-color-green;
  color: $textalternative;
  &:active {
    background-color: darken($theme-color-green, 5%) !important;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: lighten($theme-color-green, 15%) !important;
    border-color: $theme-color-green;
    color: $textalternative;
  }
  &.invert {
    background-color: $textalternative;
    color: $theme-color-green;
    &:hover,
    &:focus,
    &:active {
      color: $textalternative;
    }
  }
}
.blue-action-btn {
  background-color: $theme-color-blue;
  border-color: $theme-color-blue;
  color: $textalternative;
  &:active {
    background-color: darken($theme-color-blue, 5%) !important;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: lighten($theme-color-blue, 15%) !important;
    border-color: $theme-color-blue;
    color: $textalternative;
  }
}
.orange-action-btn {
  background-color: darken($theme-color-orange, 4%);
  border-color: darken($theme-color-orange, 4%);
  color: $textalternative;
  &:active {
    background-color: darken($theme-color-orange, 3%) !important;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: lighten($theme-color-orange, 5%) !important;
    border-color: $theme-color-orange;
    color: $textalternative;
  }
  &.invert {
    background-color: $textalternative;
    color: $theme-color-orange;
    &:hover,
    &:focus,
    &:active {
      color: $textalternative;
    }
  }
}
.white-action-btn {
  background-color: $background;
  // border-color: ;
  color: $textstandard;
  font-weight: 400;
  &.green-skin {
    color: $theme-color-green;
    &:active {
      background-color: darken($background, 3%) !important;
    }
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $theme-color-green;
      border-color: $theme-color-green;
      color: $textalternative;
    }
  }
  &.outlined {
    color: $theme-color-blue;
    border: 1px solid $theme-color-blue;
  }
  &:active {
    background-color: darken($background, 3%) !important;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $theme-color-blue;
    color: $textalternative;
  }
}
.cancel-btn {
  background-color: transparent;
  border-color: $textstandard;
  color: $textstandard;
  opacity: 0.7;
  &:active {
    background-color: $background;
    opacity: 0.9;
  }
}

.back-btn {
  background-color: transparent;
  border-color: transparent;
  color: $textstandard;
  opacity: 0.7;
  box-shadow: none;
  &:active {
    background-color: $background;
    opacity: 0.9;
  }
}

.modal-confirm-label {
  @include flexbox(0 0 auto, row, flex-start, center);
  font-size: 1.2rem;
  padding: 1rem 0;
  .sub {
    width: 70%;
  }
  .icon {
    color: $theme-color-orange;
    font-size: 1.9rem;
    padding: 1rem 1rem 1rem 0;
  }
}
.modal-btn-row {
  @include flexbox(0 0 auto, row, flex-end);
  .btn {
    margin: 0.3rem;
    font-size: 1rem;
  }
  .dotted-connector {
    @include bg-border(center);
    width: 1.5rem;
    height: 1rem;
    margin-right: 0;
  }
}

.outlined-btn {
  @include flexbox(0 0 auto, row, center, center);
  background: #008ace;
  color: $textalternative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.6rem 3.5rem 0.6rem 3rem;
  border-radius: 1.5rem;
  border: 2px solid $textalternative;
  box-shadow: 0px 0px 1rem rgba(132, 176, 197, 0.74);
  background: #eb01a5;
  // background-image: url("IMAGE_URL"); /* fallback */
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADKADAAQAAAABAAAADQAAAAANpPHXAAAAkUlEQVQoFWP4//9/JhDLMhAJGIGK+4Fq/wPxBSDewsjI+A6fXhaoJCOQNgRiXaABR4H0HqDGL1A5FApmA4ogkPMDiPeDMFDjb2RJXBpgaj4AGTuB+BRQ4z+QICENIDUg8AqItwI1XSJWA1gXkNgL8zRMABcNt4GQBgw/4NKAM5TQNfwBuglvPMA0EB3TDKSmJQAQFEJ/h7HZXwAAAABJRU5ErkJggg==)
      no-repeat 90% center/auto 30%,
    linear-gradient(#9bc4d8 0%, #2990c2 100%);

  @include flexbox(0 0 auto);
  user-select: none;
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
    box-shadow: 0px 0px 0.5rem rgba(132, 176, 197, 0.74);
  }
}
