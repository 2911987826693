.common-date-picker {
  display: flex;
  position: relative;

  &.disabled{
    pointer-events: none;
    opacity: 0.5;
  }

  .ant-picker {
    opacity: 0;
    position: absolute;
    width: 11em;
    height: 4em;
    margin-left: 1.5em;
  }
  .date-picker-control-wrapper {
    @include flexbox(0 0 auto, row, center, flex-end);
    .date-picker-text-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 0.6rem;

      .action-tag {
        padding: 0.1rem 0.5em;
        border-radius: 3px;
        font-size: 0.7rem;
        line-height: 1rem;
        height: auto;
        width: fit-content;
        color: $textalternative;
        text-transform: uppercase;
        &.pending {
          background-color: $theme-color-blue;
        }
        &.inprogress {
          background-color: darken($theme-color-blue, 5%);
          opacity: 0.8;
        }
      }
      .date-upper-txt {
        font-size: 0.9rem;
        margin: 0.35rem 0;
        text-transform: uppercase;
        .special-txt {
          color: $theme-color-blue;
          font-weight: 600;
        }
        .special-txt-year {
          font-weight: 600;
        }
      }
      .date-bottom-txt {
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      .lock-icon{
        opacity: 0.7;
        margin-left: 0.4rem;
        transition: opacity ease-in-out 150ms;
        cursor: pointer;
        &:hover{
          opacity: 1;
        }
      }
    }
    .white-action-btn {
      padding: 0.5rem 0.8em;
      font-size: 0.65rem;
      border-radius: 4px;
      top: 38px;
      height: 2em;
      margin-left: 1em;
    }
    .anticon-right,
    .anticon-left {
      display: inline-block;
      @include flexbox(0 0 auto, row, center, center);
      font-size: 1.2rem;
      padding-bottom: 0.2rem;
      // background-color: red;
      margin: 0;
      opacity: 0.7;
      transition: color ease-in-out 200ms;
      &:hover {
        cursor: pointer;
        color: $theme-color-blue;
      }
    }
  }
  .ant-select {
    position: absolute;
    height: 3rem;
    width: 15rem;
    left: 1.7rem;
    opacity: 0;
    .ant-select-selector {
      height: 4rem;
    }

    .ant-select-selector {
      height: 3rem !important;
      background-color: royalblue;
    }
  }
  .ant-select-dropdown .ant-select-item .ant-select-item-option-content {
    font-size: 0.7rem;
    line-height: 0.8rem;
    height: 0.8rem;
    padding: 0;
    .bold {
      font-weight: 600;
      font-size: 0.8rem;
      margin-right: 4px;
    }
  }
  .ant-select-dropdown .ant-select-item {
    //min-height: 30px;
    max-width: 14rem;
    line-height: 32px;
  }
  .ant-select-item-option {
    display: flex;
    align-items: center;
  }

  &.larger {  
    padding-left: 0.5rem;
    .ant-picker {
      opacity: 0;
      position: absolute;
      width: 11rem;
      height: 4rem;
      margin-left: 1.5rem;
    }
    .date-picker-control-wrapper {
      .date-picker-text-wrapper {
        margin: 0 0.6rem;
  
        .date-upper-txt {
          font-size: 1.2rem;
          margin: 0.5rem 0;
        }
        .date-bottom-txt {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
        }
      }      
      .anticon-right,
      .anticon-left {
        font-size: 1.5rem;
        padding-bottom: 0.3rem;    
      }
    }
    .ant-select {
      position: absolute;
      height: 3.8rem;
      width: 100%;
      left: 1.6rem;
      opacity: 0;
      .ant-select-selector {
        height: 3.8rem;
      }
  
      .ant-select-selector {
        height: 3.75rem !important;
        background-color: royalblue;
      }
    }
    .ant-select-dropdown .ant-select-item {
      //min-height: 30px;
      max-width: 100%;
    }
    .ant-select-dropdown .ant-select-item .ant-select-item-option-content {
      font-size: 0.9rem;
      line-height: 1rem;
      height: 1.1rem;
      padding: 0;
      .bold {
        font-weight: 600;
        font-size: 1.2rem;
        margin-right: 4px;
      }
    }
  }
}
