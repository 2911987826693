//Main Theme Colors
$background: white;
$textstandard: #707070;
$textalternative: white;
$textalternative-faded: rgba(255, 255, 255, 0.5);
$faded-background-light: rgba(255, 255, 255, 0.6);

$theme-color-blue: #008ace;
$theme-color-darkblue: #1c4f72;
$theme-color-lightblue: #91c0e5;

$theme-color-green: #54ae44;
$theme-color-lightgreen: #a4c783;
$theme-color-darkgreen: #155a32;

$theme-color-yellow: #f1c40f;
$theme-color-lightyellow: #fff6e3;

$theme-color-red: #e95c56;
$theme-color-orange: #E6A275;
$theme-color-darkorange: #e28a2a;

$bluegrey: #507B8F;
$bluegrey2: #7792a5;

$theme-color-purple: #8e44ad;

//Grid Colors
$gridstripes: lighten($theme-color-blue, 58%);
$gridhighlight: #fff7e4;

//Modals
$modalheader: #f6f9fc;

//Price Group Colors
$price-group-color-1: #A18976;
$price-group-color-2: #507B8F;
$price-group-color-3: #4B8BA8;
$price-group-color-4: #4BA88C;