.status-panel {
  @include flexbox(0 0 auto, column, center, center);
  padding: 2rem 5rem;
  width: 45vw;
  // margin-top: -5vw;
  border-radius: 0.8rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  text-align: center;
  font-size: 1.25rem;
  @include breakpoint(xs) {
    width: 100%;
    margin: 1rem 0;
  }
  h1 {
    font-weight: 700;
    opacity: 0.8;
  }
  .desc {
    // padding: 0 1.5rem;
    font-size: 1.3rem;
  }
  a:link {
    text-decoration: underline;
  }
  .home-btn {
    text-transform: capitalize;
    margin: 2rem 0;
    color: $textalternative;
    font-size: 1.9rem;
    font-weight: 800;
    padding: 2.2rem 2.5rem;
    border-radius: 0.8rem;
    background: linear-gradient(45deg, #e6c65a 0%, #e6a176 100%);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    &:hover {
      filter: brightness(1.05);
    }

    &:active {
      opacity: 1;
      filter: brightness(1.1);
    }
  }
  &.error {
    @include breakpoint(xs) {
      width: 100%;
      margin: 1rem 0;
    }
    width: 35vw;
  }
  &.confirm-page {
    padding: 1rem 5rem;
    @include breakpoint(xs) {
      width: 100%;
      margin: 1rem 0;
    }
    width: 35vw;
    max-width: 40rem;
  }
  .confirm-data {
    border: 1px solid lighten($textstandard, 40%);
    border-radius: 0.8rem;
    padding: 2rem 2rem 1rem 2rem;
    .selected-floor {
      label {
        font-size: 1.6rem;
        font-weight: 400;
      }
      margin: 1.3rem 0 1.6rem 0;
      strong {
        margin-left: 0.3rem;
        font-weight: 700;
        opacity: 0.6;
        padding: 0 1rem;
        border-radius: 0.5rem;
        background: darken($textstandard, 7%);
        color: $textalternative;
      }
    }
    .total {
      margin-top: 0.2rem;
      font-weight: 800;
    }
    .data-row {
      border-bottom: 1px solid lighten($textstandard, 40%);
      padding: 1rem 0;
      font-size: 1.5rem;
      span {
        font-weight: 800;
        margin-left: 0.5rem;
      }
      p {
        font-size: 1rem;
        margin-top: 0.6rem;
      }
    }
  }
}
.content.status-page {
  @include flexbox(1 0 auto, column, center, center);
  overflow: auto;
  padding: 3rem 0;
}
