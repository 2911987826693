// The following contains overides and customization for the Ant design framework (https://ant.design)

// Table styles
.ant-table-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.ant-table-header {
  background: transparent !important;
}
.ant-table-thead > tr > th {
  background: transparent !important;
  color: $textstandard !important;
  font-weight: 600 !important;
  padding: 0.6rem 0.7rem;
  &:first-of-type {
    padding-left: 1rem;
  }
}
.ant-input {
  padding: 0.293rem 0.6rem;
}
.ant-input::placeholder {
  font-style: italic;
  font-weight: 400;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent !important;
}
.ant-input,
.ant-radio,
.ant-select,
.ant-radio-wrapper,
.ant-input-affix-wrapper,
.ant-input-prefix-wrapper,
.Select-control {
  font-size: 1rem;
}
.ant-input-affix-wrapper {
  padding: 0.3rem 0.8rem;
}

.ant-select-item {
  font-size: 1rem;
  line-height: 2.3rem;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2.3rem;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-style: italic;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2.3rem;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 2rem;
}
.ant-select-item {
  min-height: 2.5rem;
  padding: 0.3rem 0.8rem;
}
.Select-control {
  height: 2.2rem !important;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 2.2rem !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
}
.Select-input {
  height: 2.3rem !important;
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;

  @include breakpoint(md) {
    margin-top: -0.4rem !important;
  }
  @include breakpoint(lg) {
    margin-top: 0 !important;
  }
  & > input {
    line-height: 1.3rem;
  }
}
.Select-arrow-zone {
  // padding-top: 0.5rem;
  height: 2.3rem !important;
  width: 1.7rem !important;
  padding-right: 0.33rem !important;
  .Select-arrow {
    border-width: 0.3rem 0.3rem 0.16rem;
  }
}

.Select.is-open > .Select-control .Select-arrow {
  top: -0.26rem !important;
  border-width: 0 0.3rem 0.3rem !important;
}

.ant-picker-input > input {
  // padding: 0.3rem 0.4rem;
  font-size: 1rem;
  line-height: 1.6rem;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  height: 1.86rem;
  line-height: 1.86rem;
}

.ant-picker-time-panel-column {
  width: 3.73rem;
}

.ant-picker-time-panel .ant-picker-content {
  height: 14.9rem;
}
.ant-picker-dropdown {
  font-size: 1rem;
}

.ant-table {
  font-size: 1rem;
  tbody {
    tr:not(.ant-table-expanded-row) {
      td {
        border: none;
        padding: 0.6rem 0.7rem;
        &:first-of-type {
          padding-left: 1rem;
        }
        overflow: hidden;
      }
      &:nth-child(odd):not(.ant-table-row-hover) td {
        background-color: $gridstripes;
      }
      &.ant-table-row-selected td {
        background-color: darken($gridhighlight, 3%) !important;
      }
      &:hover td {
        background-color: $gridhighlight !important;
        // color: $textalternative;
      }
      &.selected {
        td,
        &:nth-child(odd) td,
        &.ant-table-row-selected td {
          background: $gridhighlight !important;
        }
        &:hover td {
          background-color: $gridhighlight !important;
        }
      }
    }
  }
}
.ant-table-row-hover td {
  background-color: darken($theme-color-lightyellow, 3%) !important;
}
.ant-table tbody tr.ant-table-expanded-row,
.ant-table tbody tr.ant-table-expanded-row:hover {
  background: white !important;
  box-shadow: inset 0px 0px 11px 0px rgba(0, 0, 0, 0.17);

  td {
    background: transparent !important;
  }
}
.ant-table tbody tr.ant-table-expanded-row .ant-table-wrapper {
  margin-left: 0 !important;
}
.ant-table-fixed-left {
  box-shadow: 17px -1px 25px -27px rgba(62, 92, 119, 0.98) !important;
  // border-bottom: 20px solid white;
  // padding-bottom: 1rem;
  // overflow: hidden;
  border-right: 2px solid white;
  z-index: 3 !important;
}
.ant-table-body {
  overflow: auto !important;
}
.ant-table-column-sorters {
  padding: 0;
}
.ant-pagination {
  &.ant-table-pagination {
    margin: 2rem 0;
  }

  padding: 0 1rem !important;
  font-size: 1rem;
  @include flexbox(1 1 auto, row, flex-start, flex-start);
  flex-wrap: wrap;
  li {
    border-radius: 0.5rem;
    min-width: 2.5rem;
    padding: 0;
    @include flexbox(0 0 auto, row, center, center);
    line-height: 1;
    height: 2rem;
  }
}
.ant-pagination-item-active {
  font-weight: 600;
  background: $bluegrey;
  border-color: $bluegrey;
}
.ant-pagination-item, .ant-pagination-item-link {
  margin-right: 0.5rem;
  a{
    padding: 0;
    color:$textstandard;
  }
}


.ant-pagination-item-active a{
  padding: 0;
  color: $textalternative;
}
//Popover styles
.ant-popover-inner {
  border-radius: 1rem !important;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 0.65rem;
  font-size: 1.2rem;
}

.ant-table-body-outer {
  background: $background;
}

.ant-tooltip .ant-tooltip-inner {
  border-radius: 0.5rem;
  font-family: neo-sans, sans-serif;
  font-size: 1rem;
}

.ant-tree {
  font-size: 1rem;
}

.ant-checkbox-inner {
  border-radius: 50%;
}
