/***********************************************************
    Donations Site for Sunday School Building
    St. Therese Church Thimbirigasaya
    PRIMARY SASS FILE
    Author: Michael Lu 
    (Auto Compiled to CSS during runtime)
***********************************************************/

@charset "UTF-8";

// Initial Imports and resets
@import 'bt-ux-framework/bt-ux-framework';

//sttc app Imports
@import 'apps/sttc/sttc';

