.spinner {
  /* Spinner size and color */
  width: 1.5rem;
  height: 1.5rem;
  border-top-color: $theme-color-blue;
  border-left-color: $theme-color-blue;

  /* Additional spinner styles */
  animation: spinner 0.9s linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Optional — create your own variations! */
.spinner-large {
  width: 4rem;
  height: 4rem;
  border-width: 6px;
}

.spinner-slow {
  animation: spinner 1.5s linear infinite;
}

.spinner-grey {
  border-top-color: $textstandard;
  border-left-color: $textstandard;
}
