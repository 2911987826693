.toggle {
  cursor: pointer;
  display: inline-block;
}
.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 2.5rem;
  height: 1.3rem;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: '';
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.25s;
  }
  .toggle:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  }
  .toggle-checkbox:checked + & {
    background: $theme-color-blue;
    &:before {
      left: 1.2rem;
    }
  }
}
.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}
.toggle-label {
  margin-left: 0.25rem;
  position: relative;
  color: $textstandard;
  opacity: 0.7;
}
