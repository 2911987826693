.media-column {
  // @include flexbox(0 0 auto, row, flex-start, flex-start);
  width: 45vw;
  border-radius: 0.7rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.26);
  @include breakpoint(xs) {
    width: 100%;
  }
  .media-slide {
    position: relative;
    height: 30vw;
    width: 45vw;
    background-color: lighten($theme-color-orange, 40%);
    border-radius: 0.8rem;
    background-size: cover;
    background-position: center center;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    @include breakpoint(xs) {
      height: 25rem;
      width: 100%;
    }
    &.progress-slider {
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-color: $textalternative;
      background-blend-mode: normal;
    }
  }
  .thumbnails {
    @include flexbox(0 0 auto, row, flex-start, flex-start);
    padding: 1rem;
    overflow: auto;
    margin-right: 1rem;
    // width: 100%;
  }
  .media-slide-thumbnail {
    @include flexbox(0 0 auto, row, flex-start, flex-start);
    border: 1px solid lighten($textstandard, 30%);
    background-size: cover;
    background-position: center center;
    background-blend-mode: multiply;
    height: 4rem;
    width: 4rem;
    margin: 0.5rem;
    border-radius: 0.3rem;
    transition: all ease-in-out 250ms;
    cursor: pointer;
    &.selected {
      border: 1px solid $theme-color-orange;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $theme-color-orange;
      // background-color: $theme-color-orange;
    }
    &:active {
      opacity: 1;
      filter: brightness(1.1);
      border-color: $theme-color-orange;
      background-color: $theme-color-orange;
    }
  }
  .label {
    @include flexbox(0 0 auto, row, center, center);
    height: 3rem;
    margin: 0.5rem;
    font-size: 1.3rem;
  }
  .floor-number {
    @include flexbox(0 0 auto, row, center, center);
    border: 1px solid lighten($textstandard, 30%);
    background-size: cover;
    background-position: center center;
    height: 3rem;
    width: 3rem;
    margin: 0.5rem;
    border-radius: 0.3rem;
    transition: all ease-in-out 150ms;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
    &.selected {
      font-weight: 900;
      color: $textalternative;
      border-color: $theme-color-orange;
      background-color: $theme-color-orange;
    }
    &:hover {
      font-weight: 700;
      color: $textalternative;
      border-color: lighten($theme-color-orange, 5%);
      background-color: lighten($theme-color-orange, 5%);
    }
    &:active {
      opacity: 1;
      filter: brightness(1.1);
    }
  }
  .credits {
    padding: 1rem;
    line-height: 1.3;
    strong {
      // color: $theme-color-orange;
    }
  }
}

.info-column {
  // @include flexbox(0 0 auto, column, flex-start, flex-start);
  font-size: 1.3rem;
  line-height: 1.2;
  padding: 0 2rem 0 3rem;
  @include breakpoint(xs) {
    padding: 2rem 1rem 2rem 1rem;
  }
  h1,
  h2 {
    margin: 0 0 1rem 0;
    opacity: 0.6;
  }
  h1 {
    font-weight: 600;
  }
  h2 {
    font-weight: 400;
  }
  h3 {
    font-weight: 300;
    margin: 2.5rem 0;
    font-size: 1.2rem;
    strong {
      font-weight: 600;
      color: $theme-color-orange;
    }
  }
  p {
    margin: 1.5rem 0;
    font-weight: 300;
  }
  .donation {
    position: relative;
    .donation-progress {
      .donation-progress-header,
      .donation-progress-values {
        @include flexbox(0 0 auto, row, flex-start, flex-start);
        font-size: 1.6rem;
        margin: 0.5rem 0.2rem;
        @include breakpoint(xs) {
          font-size: 1.5rem;
        }
        .right {
          margin-left: auto;
          text-align: right;
        }
      }
      .donation-progress-values .left {
        font-weight: 700;
      }
      .ant-progress-inner {
        box-shadow: 0px 0px 0.3rem rgba(0, 0, 0, 0.12);
        background-color: #fff;
        border: 1px solid #ccc;
        overflow: visible;
      }
      .ant-progress-success-bg,
      .ant-progress-bg {
        box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.12);
      }
    }

    .donate {
      @include flexbox(0 0 auto, row, flex-start, center);
      margin-top: 0.2rem;
      width: 100%;
      @include breakpoint(xs) {
        margin-top: 5rem;
        @include flexbox(0 0 auto, column, flex-start, center);
      }
      .security-notice {
        @include flexbox(1 1 auto, row, flex-start, center);
        // max-width: 33vw;
        @include breakpoint(xs) {
          margin-top: 2rem;
        }
        img {
          margin: 0 1rem;
        }
      }

      .donate-btn {
        color: $textalternative;
        font-size: 1.7rem;
        font-weight: 800;
        padding: 2rem 2rem;
        border-radius: 0.8rem;
        background: linear-gradient(45deg, #e6c65a 0%, #e6a176 100%);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
        &:hover {
          filter: brightness(1.05);
        }

        &:active {
          opacity: 1;
          filter: brightness(1.1);
        }
      }
    }
    .connector {
      position: absolute;
      border-left: 1px dotted $textstandard;
      border-top: 1px dotted $textstandard;
      border-bottom: 1px dotted $textstandard;
      width: 5rem;
      height: 6.5rem;
      left: -1.5rem;
      top: 3.5rem;
      @include breakpoint(xs) {
        width: 5rem;
        height: 11rem;
        left: -1.5rem;
        top: 5.5rem;
      }
    }
  }
}

.ant-carousel .slick-dots li button {
  background: $textstandard;
}
.ant-carousel .slick-dots li.slick-active button {
  background: $theme-color-orange;
}

.donate-home {
  .donation {
    margin-top: 4rem;
  }
}
