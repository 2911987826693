/***********************************************************
    Donations Site for Sunday School Building
    St. Therese Church Thimbirigasaya
    APP SASS FILE
    Author: Michael Lu 
    (Auto Compiled to CSS during runtime)
***********************************************************/

//sttc app Imports
@import './actionbuttons/actionbuttons';
@import './common/_header';
@import './common/_footer';
@import './home/_home';
@import './donate/_donate';
@import './statuses/_statuses';

body {
  background: #fff url(../../images/bg.jpg);
  background-size: cover;
  display: flex;
}

.content {
  @include flexbox(1 0 auto, row, flex-start, flex-start);
  width: 100%;
  padding: 0 2rem;
  @include breakpoint(xs) {
    @include flexbox(0 0 auto, column, flex-start, flex-start);
  }
}

a {
  &:link {
    color: $textstandard;
  }
  color: $textstandard;
  &:hover {
    color: $theme-color-orange;
    text-decoration: none;
  }
}

.privacy-policy {
  font-size: 1.2rem;
  h3 {
    margin-top: 3rem;
  }
  h4 {
    margin-top: 1.5rem;
  }
  h3,
  h4 {
    font-weight: bold;
  }
}
