a {
  &:link {
    text-decoration: none;
    color: $theme-color-blue;
  }
  &:visited {
    color: $textstandard;
  }
  &:hover {
    transition: color 200ms ease-in-out;
    color: $theme-color-blue;
    text-decoration: underline;
  }
  &:active {
    color: $theme-color-blue;
  }
}
