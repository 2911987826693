footer {
  width: 100%;
  padding: 0.5rem 2rem;
  @include flexbox(0 0 auto, row, flex-start, center);
  flex-wrap: wrap;
  @include breakpoint(xs) {
    @include flexbox(0 0 auto, column, flex-start, center);
    padding: 2rem 2rem;
    border-top: 1px dotted #c9c9c9;
    margin-top:2rem;
  }
  .footer-links {
    @include flexbox(0 0 auto, row, flex-start, center);
    margin-left: 1rem;
    li {
      font-size: 0.9rem;
      margin: 0.8rem;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .contact-label {
    margin-left: 2rem;
    @include breakpoint(xs) {
      margin: 2rem;
      text-align:center;
      font-size: 1.3rem;
    }
  }
  .created-by{
    @include flexbox(0 0 auto, row, flex-start, center);
    margin-left: auto;
    img{
      margin-left: 0.5rem;
      margin-top: -0.5rem;
    }
    @include breakpoint(xs) {
      margin:1rem 0 0 0;
    }
  }
}
